class ProyectoService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    proyecto: {
      uri: `/v1/proyectos/guardar`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default ProyectoService;
